<template>
    <div class="min-h-screen bg-gray-50 flex flex-col justify-center">
        <div class="my-2 sm:mx-auto sm:w-full sm:max-w-4xl">
            <router-link
                class="text-blue-900 flex items-center inline-block hover:underline"
                :to="{ name: homeRouteName }"
            >
                <svg
                    class="h-4 w-4 inline-block fill-current align-middle mr-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                >
                    <path
                        fill-rule="evenodd"
                        d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
                Retour au site
            </router-link>
        </div>
        <div class="sm:mx-auto sm:w-full sm:max-w-xl pt-2">
            <img class="mx-auto h-12 w-auto" src="@/assets/imgs/naef-logo.svg" alt="NAEF Logo" />
            <h2
                class="mt-3 text-center text-2xl leading-9 font-bold text-blue-900"
            >Fiche de réservation</h2>
        </div>
        
        <transition name="fade">
            <div
                class="mt-2 sm:mx-auto sm:w-full sm:max-w-4xl"
                id="fiche-reservation"
                v-if="application"
            >
                <feedback
                    class="mb-4"
                    v-if="feedback.type"
                    :type="feedback.type"
                    :message="feedback.message"
                ></feedback> 
                <div class="container mx-auto pb-4">
                    <Header></Header>
                    <transition name="fade">
                        <section  v-if="step == 1">
                            <LotInformations :lot="lot" :application="application"></LotInformations>
                            <div class="border-l-2 border-blue-600 bg-blue-100 text-blue-600 px-4 py-3 mt-2" v-if="application.first_reservation_at">
                                Vous avez déjà rempli votre fiche de réservation. Vous pouvez tout de même modifier votre saisie.
                            </div>
                        </section>
                    </transition>
                    <transition name="fade">
                        <ApplicationForm v-if="step == 2" :disabled="readOnly"></ApplicationForm>
                    </transition>
                    <transition name="fade">
                        <ApplicationForm v-if="step == 3" :isSecondCandidate="true" :disabled="readOnly"></ApplicationForm>
                    </transition>
                    <transition name="fade">
                        <FinancialForm v-if="step == 4" :disabled="readOnly"></FinancialForm>
                    </transition>
                    <transition name="fade">
                        <FinancialForm v-if="step == 5" :isSecondCandidate="true" :disabled="readOnly"></FinancialForm>
                    </transition>
                    <div
                        class="mt-4 grid grid-cols-2"
                        v-if="!application.reservation_confirmed_at || isBroker"
                    >
                        <div class="flex justify-center md:justify-start">
                            
                            <button
                                :class="{...buttonClass, ...navigationButtonClass}"
                                v-if="step > 1"
                                @click="prev"
                            >
                                Précédent
                            </button>
                        </div>
                        <div class="flex justify-center md:justify-end">
                            <transition name="fade">
                                <button
                                    v-if="step < nbSteps"
                                    :class="{...buttonClass, ...navigationButtonClass}"
                                    @click="next"
                                >
                                    Suivant
                                </button>
                            </transition>
                            <transition name="fade">
                                <button 
                                    v-if="(step === nbSteps) && !isNotary && !isPromoter && !isBanker"
                                    :class="{...buttonClass, ...confirmButtonClass}"
                                    :disabled="isDisabled"
                                    @click="submit"
                                >
                                    Confirmer
                                </button>
                            </transition>
                        </div>
                    </div>
                    <div class="border-l-2 border-blue-600 bg-blue-100 text-blue-600 px-4 py-3" v-else>
                        Votre fiche de réservation a été validée par votre courtier. Pour toute modification, veuillez le contacter via la rubrique
                        <router-link :to="{name : 'message'}" class="underline">"messages"</router-link>de votre espace personnel.
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Vue from "vue";
import Auth from "@/mixins/Auth";
import LotInformations from "./partials/LotInformations";
import ApplicationForm from "./partials/ApplicationForm";
import FinancialForm from "./partials/FinancialForm";
import Header from "./partials/Header";
import { mapGetters } from "vuex";
import Feedback from "@/components/global/Feedback";

export default {
    mixins: [Auth],
    components: { LotInformations, ApplicationForm, FinancialForm, Feedback, Header },
    data(){
        return {
            isDisabled: false,
        };
    },    
    computed: {
        ...mapGetters("reservationForm", ["mainCandidate", "secondCandidate", "candidacy1", "candidacy2", "feedback", "application", "step", "nbSteps"]),
        lot() {
            return this.application ? this.application.lot : null;
        },
        buttonClass(){
            return {
                "p-2 rounded-md shadow-sm border hover:shadow-lg transition duration-300 font-medium text-sm focus:outline-none" : true,
                "cursor-pointer" : this.application,
                "cursor-not-allowed" : !this.application,
            };
        },
        navigationButtonClass(){
            return {
                "bg-white text-gray-700 border-blue-900" : true,
            };
        },
        confirmButtonClass(){
            return {
                "bg-blue-900 text-white border-blue-900" : true
            };
        },
        saveButtonClass(){
            return {
                "bg-blue-900 border-white text-white" : true
            };
        },
        readOnly(){
            // Les courtiers peuvent toujours éditer le formulaire
            if (this.isBroker) return false; 

            // Les candidats peuvent éditer seulement si la réservation n'a pas encore été confirmée.
            if (this.isCandidate && !this.application.reservation_confirmed_at) return false; 

            return true;
        },
    },
    
    mounted() {
        this.getApplication(this.$route.params.id);
    },

    methods: {
        submit() {
            if(this.validate()){
                this.isDisabled = true;
                const command = {
                    name : "submitReservationForm",
                    params : {
                        entity : "application",
                        entity_id : this.application.id,
                        additionnal_fields : {
                            candidacy1 : this.candidacy1,
                            candidacy2 : this.candidacy2,
                        }
                    }
                };
                this.$store.dispatch("app/executeCommand", command).then(() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }).finally(() => {
                    this.isDisabled = false;
                });

            }
        },
        validate(){
            if(this.secondCandidate){
                if (this.mainCandidate.email === this.secondCandidate.email) {
                    Vue.$toast.open({
                        message: "Vous ne pouvez pas avoir la même adresse email entre les deux candidats",
                        type: "error",
                    });
                    return false;
                }
            }
            // check email regex
            // if(this.secondCandidate){
            //     if (this.mainCandidate.email && this.secondCandidate.email) {
            //         Vue.$toast.open({
            //             message: "Vous ne pouvez pas avoir la même adresse email",
            //             type: "error",
            //         });
            //         return false;
            //     }
            // }
            return true;
        },
        getApplication(id) {
            this.$store.dispatch("auth/user").then(() => {
                this.$store.dispatch("reservationForm/loadApplication", id);
            });
        },
        scrollTop(duration) {
            // cancel if already on top
            if (document.scrollingElement.scrollTop === 0) return;

            const totalScrollDistance = document.scrollingElement.scrollTop;
            let scrollY = totalScrollDistance, oldTimestamp = null;

            function step (newTimestamp) {
                if (oldTimestamp !== null) {
                    // if duration is 0 scrollY will be -Infinity
                    scrollY -= totalScrollDistance * (newTimestamp - oldTimestamp) / duration;
                    if (scrollY <= 0) return document.scrollingElement.scrollTop = 0;
                    document.scrollingElement.scrollTop = scrollY;
                }
                oldTimestamp = newTimestamp;
                window.requestAnimationFrame(step);
            }
            window.requestAnimationFrame(step);
        },
        next(){
            this.$store.dispatch("reservationForm/next");
            this.scrollTop(500);
        },
        prev(){
            this.$store.dispatch("reservationForm/prev");
            this.scrollTop(500);
        }
    },
};

</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: all .2s;
}
.fade-enter-to{
    transition-delay: .7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>