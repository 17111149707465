<template>
  <nav class="bg-white border-b border-gray-200" v-if="user">
    <div class="max-w-7xl mx-auto pl-4 pr-2">
      <div class="flex justify-between h-12">
        <div class="-mr-2 flex items-center lg:hidden">
          <!-- Mobile menu button -->
          <button
            @click="isMobileMenuOpen = !isMobileMenuOpen"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
          >
            <!-- Menu open: "hidden", Menu closed: "block" -->
            <svg
              class="block h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!-- Menu open: "block", Menu closed: "hidden" -->
            <svg
              class="hidden h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start" v-if="user">
          <div class="flex-shrink-0 flex items-center">
            <img
              class="block lg:hidden h-8 w-auto"
              src="@/assets/imgs/naef-logo.svg"
              alt="Naef logo"
            />
            <img
              class="hidden lg:block h-8 w-auto"
              src="@/assets/imgs/naef-logo.svg"
              alt="logo Naef"
            />
          </div>
          <div class="hidden lg:-my-px lg:ml-6 lg:flex">
            <router-link
              v-if="isCandidate || isNotary || isInvestor"
              :to="{ name: 'candidate-home' }"
              class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
              >Accueil</router-link
            >
            <router-link
              v-if="!isCandidate && !isInvestorLight"
              :to="{ name: 'dashboard' }"
              :class="dashboardClass"
              >Dashboard</router-link
            >
            <router-link
              v-if="isBroker"
              :to="{ name: 'candidats' }"
              class="ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
              >Candidats</router-link
            >
            <router-link
              v-if="!isCandidate && !isPilot && !isInvestor"
              :to="{ name: 'lots' }"
              class="ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
              >Lots</router-link
            >
            <router-link
              v-if="isCandidate"
              :to="{ name: 'candidate-lots' }"
              class="ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
              >Grille des prix</router-link
            >
            <router-link
              v-if="!isCandidate && !isPilot && !isInvestor"
              :to="{ name: 'tunnel-de-vente' }"
              class="ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
              >Tunnel de vente</router-link
            >
            <router-link
              v-if="isBroker || isPromoter"
              :to="{ name: 'marketing' }"
              class="ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
              >Marketing</router-link
            >
            <router-link
              v-if="isPilot || isInvestor"
              :to="{ name: 'planning' }"
              class="ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
              >Planning</router-link
            >
            <router-link
              v-if="isPilot || isInvestor && !isInvestorLight"
              :to="{ name: 'finances' }"
              class="ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
              >Finances</router-link
            >
            <router-link
              v-if="!isNotary && !isBanker"
              :to="{ name: 'progression' }"
              class="ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
              >Avancement</router-link
            >
            <router-link
              :to="{ name: 'documents' }"
              class="ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
              >Documents</router-link
            >
            <router-link
              :to="{ name: 'message' }"
              class="ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
            >
              Messages
              <span
                v-if="user.unread_messages_count > 0"
                class="bg-red-500 rounded-full px-2 py-0 text-center object-right-top text-white text-2xs ml-1"
                >{{ user.unread_messages_count }}</span
              >
            </router-link>
            <router-link
              v-if="!isPilot && !isInvestor"
              :to="{ name: 'FAQ' }"
              class="ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out"
              >FAQ</router-link
            >
          </div>
        </div>

        <!-- Notification -->
        <dropdown-notifications class="flex items-center mr-2"/>

        <div class="hidden lg:flex lg:items-center">
          <!-- Promotion dropdown -->
          <div class="inline-block w-36">
            <picture-select
              :searchProps="['title']"
              :collection="user.promotions"
              textProp="title"
              imgProp="logo_full_path"
              valueProp="id"
              badgeProp="unread_messages"
              imgClass="rounded-0 h-10 w-10"
              :value="user.preferences.selected_promotion"
              @change="
                val => {
                  changePromotion(val);
                }
              "
            />
          </div>
          <!-- Profile dropdown -->
          <div class="relative flex" v-on-clickaway="closeProfileDropdown">
            <div>
              <button
                v-if="user"
                @click="isUserMenuOpen = !isUserMenuOpen"
                class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
                id="user-menu"
                aria-label="User menu"
                aria-haspopup="true"
              >
                <img
                  class="h-8 w-8 rounded-full"
                  :src="user.profile_picture"
                  alt
                />
              </button>
            </div>
            <transition name="menu-popover">
              <div
                @click="isUserMenuOpen = !isUserMenuOpen"
                v-show="isUserMenuOpen"
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10"
              >
                <div class="py-1 rounded-md bg-white shadow-xs">
                  <router-link
                    :to="{ name: 'settings' }"
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                    >Mon compte</router-link
                  >
                  <router-link
                    v-if="isBroker"
                    :to="{
                      name: 'promotion.view'
                    }"
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                  >Paramètres de la promotion</router-link>
                  <router-link
                    v-if="user.admin"
                    :to="{name: 'promotion.add'}"
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                  >Ajouter une promotion</router-link>
                  <a
                    @click.prevent="logout"
                    href="#"
                    class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                    >Déconnexion</a
                  >
                </div>
              </div>
            </transition>
          </div>
        </div>


      </div>
    </div>

    <!--
      Mobile menu, toggle classes based on menu state.

      Open: "block", closed: "hidden"
        -->
    <div class="lg:hidden" v-show="isMobileMenuOpen">
      <div class="pt-2 pb-3">
        <div class="p-4">
          <picture-select
            :searchProps="['title']"
            :collection="user.promotions"
            textProp="title"
            imgProp="logo_full_path"
            valueProp="id"
            imgClass="rounded-0 h-10 w-10"
            :value="user.preferences.selected_promotion"
            @change="
              val => {
                changePromotion(val);
              }
            "
          />
        </div>
        <div
          v-if="user"
          @click="
            () => {
              this.isMobileMenuOpen = false;
            }
          "
        >
          <router-link
            v-if="isCandidate || isNotary || isInvestor"
            :to="{ name: 'candidate-home' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Accueil</router-link
          >
          <router-link
            v-if="!isCandidate && !isInvestorLight"
            :to="{ name: 'dashboard' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Dashboard</router-link
          >
          <router-link
            v-if="isBroker"
            :to="{ name: 'candidats' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Candidats</router-link
          >
          <router-link
            v-if="isCandidate"
            :to="{ name: 'candidate-lots' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Grille des prix</router-link
          >
          <router-link
            v-if="!isCandidate && !isPilot && !isInvestor"
            :to="{ name: 'lots' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Lots</router-link
          >
          <router-link
            v-if="!isCandidate && !isPilot && !isInvestor"
            :to="{ name: 'tunnel-de-vente' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Tunnel de vente</router-link
          >
          <router-link
            v-if="isBroker || isPromoter"
            :to="{ name: 'marketing' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Marketing</router-link
          >
          <router-link
            v-if="isPilot || isInvestor"
            :to="{ name: 'planning' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Planning</router-link
          >
          <router-link
            v-if="isPilot || isInvestor && !isInvestorLight"
            :to="{ name: 'finances' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Finances</router-link
          >
          <router-link
            v-if="!isNotary && !isBanker"
            :to="{ name: 'progression' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Avancement</router-link
          >
          <router-link
            :to="{ name: 'documents' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Documents</router-link
          >
          <router-link
            :to="{ name: 'message' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >Messages</router-link
          >
          <router-link
            v-if="!isPilot && !isInvestor"
            :to="{ name: 'FAQ' }"
            class="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-blue-900 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            >FAQ</router-link
          >
        </div>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-200">
        <div
          @click="
            () => {
              this.isMobileMenuOpen = false;
            }
          "
          class="mt-3"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <router-link
            :to="{ name: 'settings' }"
            class="mt-1 block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
            >Mon compte</router-link
          >
          <router-link
            v-if="isBroker"
            :to="{
              name: 'promotion.view'
            }"
            class="mt-1 block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
          >Paramètres de la promotion</router-link>
          <router-link
            v-if="user.admin"
            :to="{
              name: 'promotion.add'
            }"
            class="mt-1 block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
          >Ajouter une promotion</router-link>
          <a
            @click.prevent="logout"
            href="#"
            class="mt-1 block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 focus:outline-none focus:text-gray-800 focus:bg-gray-100 transition duration-150 ease-in-out"
            role="menuitem"
            >Déconnexion</a
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { mixin as clickaway } from "vue-clickaway";

import PictureSelect from "@/components/Forms/PictureSelect";
import DropdownNotifications from "@/components/global/Notification/Dropdown";

import Auth from "@/mixins/Auth";

export default {
    mixins: [clickaway, Auth],
    components: {
        PictureSelect,
        DropdownNotifications
    },
    data() {
        return {
            isUserMenuOpen: false,
            isMobileMenuOpen: false,
        };
    },
    computed: {
        ...mapState("question", ["questions"]),
        ...mapGetters("auth", ["user", "currentPromotion", "preferences"]),
        dashboardClass() {
            if(this.isNotary || this.isInvestor){
                return "ml-4 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out";
            } else {
                return "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-blue-900 focus:outline-none transition duration-150 ease-in-out";
            }
        }
    },
    methods: {
        closeProfileDropdown() {
            this.isUserMenuOpen = false;
        },

        changePromotion(promotionId) {
            if(this.preferences.selected_promotion != promotionId)
                this.$store.dispatch("auth/changePromotion", promotionId);
        },
    },
};
</script>

<style scoped lang="scss">
.menu-popover-enter {
  @apply transform opacity-0 scale-95;
}
.menu-popover-leave-to {
  @apply transform opacity-0 scale-95;
}
.menu-popover-enter-to {
  @apply transform opacity-100 scale-100;
}
.menu-popover-leave {
  @apply transform opacity-100 scale-100;
}
.menu-popover-enter-active {
  @apply transition ease-out duration-100;
}
.menu-popover-leave-active {
  @apply transition ease-in duration-75;
}
</style>
