<template>
    <div class="p-4 overflow-y-auto">
        <div class="grid grid-cols-2 grid-col gap-4 mb-2">
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">Nom</dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ candidate.last_name }}
                </dd>
            </div>
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Prénom
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ candidate.first_name }}
                </dd>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-2">
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Genre
                </dt>
                <div id="ecm-gender-1">
                        <label for="toggle" class="text-xs text-gray-700"
                            >Homme
                        </label>
                        <div
                            class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
                        >
                            <input
                                :disabled="true"
                                type="checkbox"
                                name="toggle"
                                id="toggle"
                                v-model="genderBool"
                                class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer focus:outline-none"
                            />
                            <label
                                for="toggle"
                                class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                            ></label>
                        </div>
                        <label for="toggle" class="text-xs text-gray-700"
                            >Femme</label
                        >
                    </div> 
            </div>
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    N° AVS
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.avs, "-") }}
                </dd>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-2">
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Date de naissance
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.birthday, "-") }}
                </dd>
            </div>
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Lieu de naissance
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.birthday_city, "-") }}
                </dd>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-2">
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Commune d'origine / nationalité(s)
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.place_origin, "-") }}
                </dd>
            </div>
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Permis d'établissement
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.establishment_permit, "-") }}
                </dd>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-2">
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Actuellement propriétaire en Suisse 
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.already_swiss_owner, "non") }}
                </dd>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-2">
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Profession
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.job, "-") }}
                </dd>
            </div>
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Etat civil
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.civil_status, "-") }}
                </dd>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-2">
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Régime matrimonial
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.matrimonial_property, "-") }}
                </dd>
            </div>
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Adresse (domicile)
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.address, "-") }}
                </dd>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-2">
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Ville
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.city, "-") }}
                </dd>
            </div>
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Code postal
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.zip, "-") }}
                </dd>
            </div>
        </div>
        <div
            class="grid grid-cols-2 gap-4 mb-2"
            v-if="candidate.phone_private || candidate.phone_pro"
        >
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Tel. privé
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.phone_private, "-") }}
                </dd>
            </div>
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Tel. pro
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.phone_pro, "-") }}
                </dd>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-2">
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Tel.
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.phone, "-") }}
                </dd>
            </div>
            <div>
                <dt class="text-sm leading-5 font-medium text-gray-500">
                    Email
                </dt>
                <dd class="mt-1 text-sm leading-5 text-gray-900">
                    {{ notNullOrDefault(candidate.email, "-") }}
                </dd>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        candidate() {
            return this.$parent.candidate;
        },
        genderBool(){
            if (this.candidate.gender === "M") {
                return false;
            }
            return true;
        }
    },
    methods: {
        notNullOrDefault(elem, defaultText) {
            return elem ? elem : defaultText;
        },
    },
};
</script>

<style>
</style>