<template>
    <div
        class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8 items-center"
        id="error"
    >
        <div class="mt-8 sm:w-full sm:max-w-2xl center sm:pr-0 lg:pr-10">
            <div
                class="bg-white py-8 px-4 rounded-md sm:px-10 shadow"
                style="--bg-opacity: 0.9"
            >
                <img
                    class="mx-auto h-12 w-auto"
                    src="@/assets/imgs/naef-logo.svg"
                    alt="Naef logo"
                />
                <p class="mt-8 text-left">
                    Vous n'êtes pas autorisé à effectuer cette action.
                </p>
                <div class="mt-6" v-if="user">
                    <span class="block w-full rounded-md shadow-sm" @click="redirectHome">
                        <div class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                          Retour
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import Auth from "@/mixins/Auth";
import Cookies from "js-cookie";
import Redirect from "@/mixins/Redirect";

export default {
    mixins: [Auth, Redirect],
    data() {
        return {};
    },
    computed: {
        ...mapState("auth", ["user"]),
    },
    methods: {
        goBack(){
            Cookies.remove("token");
            this.$router.push({name : "login"});
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "E-Promotion | Erreur 401";
            }
        },
    },       
};
</script>

<style scoped>
#error {
    background-image: url("/img/login-bg.jpg") !important;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
</style>
