<template>
    <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-xl">
            <img class="mx-auto h-12 w-auto" src="@/assets/imgs/naef-logo.svg" alt="NAEF Logo" />
            <h2
                v-if="lot"
                class="mt-6 text-center text-2xl leading-9 font-extrabold text-blue-900"
            >Créer un dossier sur le lot {{ lot.title }}</h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <feedback
                    class="mb-4"
                    v-if="feedback.message"
                    :type="feedback.type"
                    :message="feedback.message"
                ></feedback>
                <form action="#" method="POST" @submit.prevent="submit">
                    <div>
                        <label
                            for="email"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >Nom *</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                v-model="candidate.last_name"
                                id="name"
                                type="text"
                                required
                                class="form-input"
                            />
                        </div>
                    </div>
                    <div class="mt-4">
                        <label
                            for="email"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >Prénom *</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                v-model="candidate.first_name"
                                id="name"
                                type="text"
                                required
                                class="form-input"
                            />
                        </div>
                    </div>
                    <div class="mt-4">
                        <label
                            for="email"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >Email *</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                v-model="candidate.email"
                                id="email"
                                type="email"
                                required
                                class="form-input"
                            />
                        </div>
                    </div>

                    <div class="mt-4">
                        <label
                            for="phone"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >Téléphone</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                v-model="candidate.phone"
                                id="phone"
                                type="phone"
                                class="form-input"
                            />
                        </div>
                    </div>

                    <div class="mt-4">
                        <label
                            for="city"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >Adresse</label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                id="address"
                                v-model="candidate.address"
                                type="text"
                                class="form-input"
                            />
                        </div>
                    </div>

                    <div class="mt-4 flex">
                        <div class="w-1/2 pr-2">
                            <label
                                for="city"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >Ville</label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    v-model="candidate.city"
                                    id="city"
                                    type="text"
                                    class="form-input"
                                />
                            </div>
                        </div>
                        <div class="w-1/2 pl-2">
                            <label
                                for="zip"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >Code Postal</label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    v-model="candidate.zip"
                                    id="zip"
                                    type="number"
                                    class="form-input"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <label
                            for="comment"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >Message</label>
                        <div class="bg-white rounded-md shadow-sm mt-2">
                            <textarea
                                v-model="candidate.comment"
                                id="comment"
                                rows="3"
                                class="border p-2 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:outline-none focus:border-blue-400 rounded"
                            ></textarea>
                        </div>
                    </div>

                    <div class="mt-12">
                        <span class="block w-full rounded-md shadow-sm">
                            <button
                                type="submit"
                                class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                            >Valider</button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
        <confirm-register-modal
            :open="openConfirmModal"
            :url="confirmUrl"
            @close="openConfirmModal = false"
        />
    </div>
</template>

<script>
import candidate from "@/api/candidate";
import lot from "@/api/lot";
import Feedback from "@/components/global/Feedback";
import ConfirmRegisterModal from "@/components/Candidates/modals/public/ConfirmRegisterModal";
//import { mapState } from "vuex";

export default {
    components: {
        Feedback,
        ConfirmRegisterModal
    },
    data() {
        return {
            candidate: {
                last_name: "",
                first_name: "",
                email: "",
                address: "",
                city: "",
                comment: "",
                zip: null
            },
            feedback: {
                message: null,
                type: null
            },
            lot: null,
            openConfirmModal: false,
            confirmUrl: null
        };
    },
    methods: {
        submit() {
            let body = {
                ...this.candidate,
                promotion_token: this.$route.params.id
            };
            candidate
                .create(body)
                .then(response => {
                    if (response.status === 200) {
                        this.feedback.type = "success";
                        this.feedback.message = "Votre dossier a bien été créé";
                        this.openConfirmModal = true;
                        this.confirmUrl = response.data.promotion.url;
                    }
                })
                .catch(error => {
                    this.feedback.type = "error";
                    this.feedback.message = error.response.data.errors[0];
                });
        },
        getLot(id) {
            lot.getOnePublic(id)
                .then(response => {
                    if (response.status === 200) {
                        this.lot = response.data.data;
                    } else {
                        this.lot = null;
                        // this.feedback.type = "error";
                        // this.feedback.message = "Lot indisponible";
                    }
                })
                .catch(() => {
                    this.lot = null;
                    // this.feedback.type = "error";
                    // this.feedback.message = "Lot indisponible";
                });
        }
    }
};
</script>
