<template>
	<div
		class="bg-white shadow overflow-hidden sm:rounded-lg border border-blue-900"
		v-if="candidate"
	>
		<div class="p-4 border-b border-gray-200 sm:px-6">
			<h3 class="text-lg leading-6 font-medium text-blue-900">
				<span v-if="isSecondCandidate">Informations personnelles candidat n°2</span>
				<span v-else>Informations personnelles candidat n°1</span>
			</h3>
			<p
				class="mt-1 max-w-2xl text-sm leading-5 text-gray-500"
			>
				<span v-if="isSecondCandidate">Laissez cette page vide si vous souhaitez acheter seul</span>
				<span v-else>Informations personnelles de l'acquéreur.</span>
				
			</p>
		</div>
		<div class="px-4 pb-4 md:p-0">
			<!-- NOM -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center">Nom</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.last_name"
						@input="(e) => handleChange('last_name', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- NOM JEUNE FILLE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center">Nom de jeune fille</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.maiden_name"
						@input="(e) => handleChange('maiden_name', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- PRENOM -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Prénom(s)</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.first_name"
						@input="(e) => handleChange('first_name', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- PRENOM PERE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Prénom(s) père</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.father_first_name"
						@input="(e) => handleChange('father_first_name', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- PRENOM MERE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Prénom(s) mère</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.mother_first_name"
						@input="(e) => handleChange('mother_first_name', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- NOM JEUNE FILLE MERE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Nom de jeune fille mère</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.mother_maiden_name"
						@input="(e) => handleChange('mother_maiden_name', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- N° AVS -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>N° AVS</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.avs"
						@input="(e) => handleChange('avs', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- GENRE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Genre</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<div class="inline-block relative w-full mr-2 h-full">
						<select
							:value="candidate.gender"
							@input="(e) => handleChange('gender', e.target.value)"
							class="block appearance-none w-full h-full bg-white border border-gray-300 hover:border-gray-900 px-4 py-2 pr-8 rounded shadow-sm leading-tight focus:outline-none focus:shadow-outline"
							:disabled="disabled"
						>
							<option value="M">Homme</option>
							<option value="F">Femme</option>
						</select>
						<div
							class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
						>
							<svg
								class="fill-current h-4 w-4"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
							>
								<path
									d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>

			<!-- DATE DE NAISSANCE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>
					Date de naissance
				</div>
				<div
					class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2"
				>
					<datetime 
						:value="candidate.birthday"
						@input="(val) => handleChange('birthday', val)"
						type="date"
						value-zone="UTC+2"
						zone="UTC+2"
						input
						input-class="form-input"
						:disabled="disabled"
					>
					</datetime>
				</div>
			</div>

			<!-- LIEU DE NAISSANCE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Lieu de naissance</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.birthday_city"
						@input="(e) => handleChange('birthday_city', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
				
			</div>

			<!-- COMMUNE ORIGINE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Commune(s) d'origine (si suisse) / nationalité(s)</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.place_origin"
						@input="(e) => handleChange('place_origin', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- PERMIS ETABLISSEMENT -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Permis d'établissement</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.establishment_permit"
						@input="(e) => handleChange('establishment_permit', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- PROPRIETAIRE EN SUISSE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Actuellement propriétaire en Suisse</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						type="checkbox"
						:checked="candidate.already_swiss_owner"
						@change="(e) => handleChange('already_swiss_owner', e.target.checked)"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- PROFESSION -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Profession</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.job"
						@input="(e) => handleChange('job', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- ETAT CIVIL -->
			<div class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
				<div class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center">Etat civil</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<div class="inline-block relative w-full">
						<!-- changer type en fonction de gender -->
						<select
							:value="candidate.civil_status"
							@input="(e) => handleChange('civil_status', e.target.value)"
							class="form-select"
							:disabled="disabled"
						>
							<option>célibataire</option>
							<option>marié</option>
							<option>partenaire enregistré</option>
							<option>divorcé</option>
							<option>veuf</option>
						</select>
						<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
							<svg
								class="fill-current h-4 w-4"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
							>
								<path
									d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>

			<!-- REGIME MATRIMONIAL -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center">
					Régime matrimonial (si mariées/partenaires
					enregistrés)
				</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.matrimonial_property"
						@input="(e) => handleChange('matrimonial_property', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- ADRESSE -->
			<div class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
				<div class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center">
					Adresse (domicile)
				</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.address"
						@input="(e) => handleChange('address', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- VILLE -->
			<div class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
				<div class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center">
					Ville
				</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.city"
						@input="(e) => handleChange('city', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- Pays -->
			<div class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
				<div class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center">
					Pays
				</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.country"
						@input="(e) => handleChange('country', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- CODE POSTAL -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Code Postal</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						type="number"
						:value="candidate.zip"
						@input="(e) => handleChange('zip', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- TELEPHONE PRIVE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Tél. privé</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.phone_private"
						@input="(e) => handleChange('phone_private', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- TELEPHONE PRO -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Tél. pro.</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.phone_pro"
						@input="(e) => handleChange('phone_pro', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- TELEPHONE PORTABLE -->
			<div
				class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5"
			>
				<div
					class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center"
				>Tél. portable</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 sm:col-span-2">
					<input
						:value="candidate.phone"
						@input="(e) => handleChange('phone', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled"
					/>
				</div>
			</div>

			<!-- EMAIL -->
			<div class="mt-3 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
				<div class="text-sm leading-5 font-medium text-gray-500 sm:col-span-1 self-center">
					Email
				</div>
				<div class="mt-1 text-sm leading-5 text-blue-900 sm:mt-0 col-span-2">
					<input
						type="email"
						:value="candidate.email"
						@input="(e) => handleChange('email', e.target.value)"
						class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						:disabled="disabled || candidate.id > 0"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import { mapGetters } from "vuex";

export default {
    components : {Datetime},
    props : {
        isSecondCandidate : {
            type : Boolean,
            default : false,
        },
        disabled : {
            type : Boolean,
        },
    },
    computed : {
        ...mapGetters("reservationForm", ["mainCandidate", "secondCandidate"]),
        candidate(){
            if(this.isSecondCandidate)
                return this.secondCandidate;
            return this.mainCandidate;
        },
    },
    methods : {
        handleChange(key, value){
            const data = {
                key,
                value
            };
            if(this.isSecondCandidate)
                this.$store.commit("reservationForm/SET_SECOND_CANDIDATE_DATA", data);
            else
                this.$store.commit("reservationForm/SET_MAIN_CANDIDATE_DATA", data);
        }
    }

};
</script>

<style>

</style>